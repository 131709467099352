import React from 'react'
import { Spinner, Center } from '@chakra-ui/react';

const Loader = ({height}) => {
  return (
    <Center height={height}>
        <Spinner size={"xl"}/>
    </Center>
  )
}

export default Loader