import axios from 'axios';
import Cookies from 'js-cookie'; // Подключаем библиотеку для работы с куки

// Получение токена из куки
const token = Cookies.get('authToken');

const instance = axios.create({
    baseURL: '/backend/api',
    withCredentials: true,
    headers: {
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Authorization': token ? `Bearer ${token}` : ''
    }
});

instance.interceptors.request.use(
    (config) => {
        const token = Cookies.get('authToken'); // Получаем токен из куки перед каждым запросом
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;