import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth';

const LoginPage = () => {
    const navigate = useNavigate();
    const { login, isAuthenticated } = useAuth();
    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });
    const [error, setError] = useState('');

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post('/backend/api/checkAuth.php', credentials)
            .then(response => {
                if (response.data.success) {
                    login(response.data.token);
                    navigate('/organization');
                } else {
                    setError(response.data.message);
                }
            })
            .catch(error => {
                setError("Ошибка входа!");
                console.error("Ошибка входа!", error);
            });
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="bg-white dark:bg-black rounded-lg shadow-lg p-6 w-full max-w-md">
                <Link to={"/"} className="bg-gray-300 dark:bg-bgDark dark:text-white text-gray-800 font-bold py-2 px-4 me-4 rounded focus:outline-none focus:shadow-outline mb-4">Назад</Link>
                <Link to={"/register"} className="bg-gray-300 dark:bg-bgDark dark:text-white text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4">Ещё нет аккаунта?</Link>
                <h2 className="text-2xl font-semibold text-center dark:text-white mb-6 mt-6">
                    Вход
                </h2>
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                        <strong className="font-bold">Ошибка!</strong>
                        <span className="block sm:inline">{error}</span>
                    </div>
                )}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Введите email"
                            value={credentials.email}
                            onChange={handleChange}
                            className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 dark:bg-bgDark dark:text-white focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
                            Пароль
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Введите пароль"
                            value={credentials.password}
                            onChange={handleChange}
                            className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 dark:bg-bgDark dark:text-white focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-6">
                        Вход
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
