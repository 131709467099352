import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    bgLight: '#B3B3B3D1',
    bgDark: "#252525D1",
    bgComponent: '#71717D1F',
    bgSelectedComponent: "#007BFE",
    SplashScreenColor: '#00F7BB'
  }
});

export default theme;