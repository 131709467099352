import React, { useState, useEffect } from 'react';
import { Tabs, TabList, Tab, Box, Avatar, Drawer, DrawerBody, DrawerContent, DrawerOverlay, DrawerHeader, useDisclosure, Text, TabPanels, TabPanel } from '@chakra-ui/react';
import axios from 'axios';
import Loader from './Loader';

const OrganizationInfo = ({ orgId, userOrgLocation, anotherOrg }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [OrgList, setOrgList] = useState([]);
  const [SelectedOrg, setSelectedOrg] = useState([]);
  const [SelectedMasters, setSelectedMasters] = useState([]);
  const [selectedMaster, setSelectedMaster] = useState(null);
  // const [nearMasters, setNearMasters] = useState([]);
  const [type, setType] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    // Имитируем загрузку данных
    setTimeout(() => {
      setOrgList([
        { OrgName: "Основательная бригада", OrgEmployersCount: 8, OrgEmployersAvailable: 3, photo: "https://via.placeholder.com/80?text=ОБ" },
        { OrgName: "Партия кусочка", OrgEmployersCount: 10, OrgEmployersAvailable: 6, photo: "https://via.placeholder.com/80?text=ПК" },
        { OrgName: "ООО Цикл", OrgEmployersCount: 9, OrgEmployersAvailable: 2, photo: "https://via.placeholder.com/80?text=Ц" }
      ]);

      setIsLoading(false); // Загрузка завершена
    }, 3000); // Имитация 2-секундной задержки
  }, []);

  useEffect(() => {
    if (!orgId) {
      return;
    }

    const fetchOrganizationData = async () => {
      setType('selected')
      try {
        const response = await axios.post('/backend/api/getOrganizationInfo.php', { org_id: orgId });
        if (response.data.success) {
          setSelectedOrg(response.data.org);
          setSelectedMasters(response.data.masters);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных организации', error);
      }
    };

    if (orgId) {  
      fetchOrganizationData();
    }
  }, [orgId]);

  const fetchMastersData = async () => {
    setType('available')
    try {
      const response = await axios.post('/backend/api/getAvailableMasters.php');
      if (response.data.success) {
        setSelectedOrg([]);
        setSelectedMasters(response.data.data);
      } else {
        console.log("Не удалось получить мастеров рядом");
      }
    } catch (error) {
      console.error('Ошибка при загрузке данных организации', error);
    }
  };

  const fetchNearMastersData = async () => {
    setType('near');

    try {
      const response = await axios.post('/backend/api/getNearOrgMasters.php', {
        org_lang: userOrgLocation[1],
        org_lat: userOrgLocation[0]
      })
      if(response.data.success){
        let resAllArr = response.data.data;
        let AllNearMastersObj = [];
        resAllArr.forEach(res => {
          res.forEach(resIn => {
            AllNearMastersObj.push(resIn)
          })
        });
        setSelectedMasters(AllNearMastersObj);

        // console.log(nearMasters)
      }
    } catch (error) {

    }
    console.log(userOrgLocation);
    // if(userOrgLocation){
    //   setSelectedMasters()
    // } else {

    // }
  }

  const HeaderType = () => {
    switch (type) {
      case 'selected':
        return (
          <>
            <h2 className="text-lg font-semibold text-gray-800 dark:text-white">
              Выбранная организация: {SelectedOrg.name}
            </h2>
            <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
              Адрес: г.{SelectedOrg.city}, {SelectedOrg.address}
            </p>
          </>
        );
      case 'near':
        return (
          <h2 className="text-lg font-semibold text-gray-800 dark:text-white">Мастера рядом</h2>
        );
      case 'available':
        return (
          <h2 className="text-lg font-semibold text-gray-800 dark:text-white">Свободные мастера</h2>
        );
      default:
        return null;
    }
  };

  const handleMasterSelect = (master) => {
    setSelectedMaster(master);
    onOpen(); 
  };

  if (isLoading) {
    return <Loader height="60vh" />;
  }

  return (
    <div className="org-info p-6 bg-white dark:bg-bgDark backdrop-blur-sm rounded-t-lg overflow-y-auto" style={{ height: "60vh" }}>
      <Tabs variant='unstyled' mb={4}>
        <TabList alignItems={"center"} justifyContent={"center"}>
          <Tab className='text-gray-800 bg-bgLight dark:bg-bgDark border dark:border-2 dark:border-white dark:text-white m-2 rounded-md px-4 py-2 w-full sm:w-auto' _selected={{ bg: 'black', color: "white" }} onClick={fetchMastersData}>Свободные</Tab>
          <Tab className='text-gray-800 bg-bgLight dark:bg-bgDark border dark:border-2 dark:border-white dark:text-white m-2 rounded-md px-4 py-2 w-full sm:w-auto' _selected={{ bg: 'black', color: "white" }} onClick={fetchNearMastersData}>Рядом</Tab>
        </TabList>
      </Tabs>

      {orgId || SelectedMasters.length > 0 ? (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} >
          

          <HeaderType />
          
          <Tabs variant='unstyled'>
            <TabList className="flex flex-wrap mt-4">
              {SelectedMasters.map((master, index) => (
                <Tab
                  key={index}
                  onClick={() => handleMasterSelect(master)}
                  className='flex items-center text-gray-800 bg-bgLight dark:bg-bgDark dark:text-white m-2 rounded-md px-4 py-2 w-full sm:w-auto'
                  _selected={{ bg: 'bgSelectedComponent', color: "white" }}
                >
                  <Avatar size={"sm"} name={master.name} mr={2} />
                  <Box display={"flex"} flexDirection={"column"}>
                    <Text>{master.name}</Text>
                    <Text>{master.is_available === 0 ? "занят" : "свободен"}</Text>
                  </Box>
                </Tab>
              ))}
            </TabList>

            <Drawer placement={"bottom"} onClose={onClose} isOpen={isOpen}>
              <DrawerOverlay />
              <DrawerContent className='dark:bg-bgDark dark:text-white'>
                <DrawerHeader borderBottomWidth='1px'>{selectedMaster ? selectedMaster.name : ''}</DrawerHeader>
                {selectedMaster && (
                  <DrawerBody className='dark:bg-bgDark'>
                    <p className='text-black dark:text-white'>Телефон: {selectedMaster.phone}</p>
                    <Text className='text-black dark:text-white'>{selectedMaster.is_available === 0 ? `Занят: 00:00:02` : `Можно вызвать`}</Text>
                    {selectedMaster.is_available === 1 && (
                      <a href={`tel:${selectedMaster.phone}`} className='flex items-center text-gray-800 bg-bgLight dark:bg-bgDark dark:text-white m-2 rounded-md px-4 py-2 w-full sm:w-auto'
                        _selected={{ bg: 'bgSelectedComponent', color: "white" }}>
                        Позвонить мастеру
                      </a>
                    )}
                  </DrawerBody>
                )}
              </DrawerContent>
            </Drawer>
          </Tabs>
        </Box>
      ) : (
        <h2 className="text-lg font-semibold text-gray-800 dark:text-white text-center">Выберите организацию, нажав по маркеру</h2>
      )}

      <h2 className="text-lg font-semibold text-gray-800 dark:text-white mt-10">Другие организации</h2>

      <Tabs variant='unstyled'>
        <TabList className="flex flex-wrap mt-4">
          {anotherOrg.map((org, index) => (
            <Tab
              key={index}
              className='flex items-center text-gray-800 bg-bgLight dark:bg-bgDark dark:text-white m-2 rounded-md px-4 py-2 w-full sm:w-auto'
              _selected={{ bg: 'bgSelectedComponent', color: "white" }}
            >
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <Avatar name={`${org.name}`} size={"lg"} mb={3} />
                <Text>{`${org.name}`}</Text>
                <Text>{`${org.city}`}</Text>
                <Text>{`${org.address}`}</Text>
              </Box>
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </div>
  );
};

export default OrganizationInfo;