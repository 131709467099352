import React, { useEffect, useState } from 'react';
import {Menu, MenuButton, MenuList, MenuItem, IconButton} from '@chakra-ui/react'
import {HamburgerIcon, SunIcon, MoonIcon, ExternalLinkIcon, ArrowBackIcon, CalendarIcon, LockIcon} from '@chakra-ui/icons'
import { useAuth } from '../context/auth';

const TooglerTheme = () => {
  const { isAuthenticated } = useAuth();

  // Получение начального значения темы из localStorage
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Проверяем наличие значения в localStorage
    const savedTheme = localStorage.getItem('theme');
    // Если в localStorage есть сохраненная тема, используем ее, иначе по умолчанию светлая тема
    return savedTheme === 'dark' || (!savedTheme && window.matchMedia('(prefers-color-scheme: dark)').matches);
  });

  // Переключение темы и сохранение в localStorage
  const toggleTheme = () => {
    setIsDarkMode(prevIsDarkMode => {
      const newTheme = !prevIsDarkMode;
      // Сохраняем новое значение в localStorage
      localStorage.setItem('theme', newTheme ? 'dark' : 'light');
      return newTheme;
    });
  };

  // Обновляем класс темы на <html> при изменении isDarkMode
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (

    <div className='fixed p-2.5 px-4 z-1000 right-4 top-4 rounded-md'>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<HamburgerIcon />}
          color={localStorage.getItem("theme") == "dark" ? "white" : "black"}
          className='bg-white dark:bg-bgDark'
          variant='outline'
        />
        <MenuList>
          <MenuItem icon={localStorage.getItem('theme') == "light" ? <SunIcon /> : <MoonIcon />} onClick={toggleTheme}>
            Поменять тему
          </MenuItem>
          {isAuthenticated ?
            <MenuItem icon={<CalendarIcon />} onClick={() => window.location.href = "/organization"}>
              Панель организации
            </MenuItem>
          :
            <MenuItem icon={<LockIcon />} onClick={() => window.location.href = "/register"}>
              Войти в личный кабинет
            </MenuItem>
          }
          <MenuItem icon={<ExternalLinkIcon />} onClick={() => window.location.href = '/form1'}>
            Начать регистрацию (открыть тестовые формы)
          </MenuItem>
          <MenuItem icon={<ArrowBackIcon />} onClick={() => window.location.href = '/'}>
            Главное меню
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
};

export default TooglerTheme;