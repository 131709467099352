import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MapView from '../components/MapView';
import OrganizationInfo from '../components/OrganizationInfo';
import L from 'leaflet';
import SplashScreen from '../components/SplashScreen';

const HomePage = () => {
  const [orgId, setOrgId] = useState(null)
  const [userOrgLocation, setUserOrgLocation] = useState(null)

  const [isLoading, setIsLoading] = useState(true);
  const [markers, setMarkers] = useState([]);
  const [mapCenter, setMapCenter] = useState(null);
  const [mapZoom, setMapZoom] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [anotherOrg, setAnotherOrg] = useState([]);

  useEffect(() => {
    setTimeout(() => {setIsLoading(false)}, 3000)
  }, [])

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await axios.get('/backend/api/getOrganizations.php');
        const organizations = response.data;

        setAnotherOrg(organizations)

        // добавил фильтр чтобы незаполненные не выводились
        const validMarkers = organizations
          .filter(org => org.latitude !== null && org.longitude !== null)
          .map(org => ({
            lat: parseFloat(org.latitude),
            lng: parseFloat(org.longitude),
            name: org.name
          }));

        setMarkers(validMarkers);
      } catch (error) {
        console.error('Ошибка при загрузке организаций', error);
      }
    };

    fetchOrganizations();

    // Определение местоположения пользователя
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        setUserLocation({ lat, lng });
      }, error => {
        console.error('Ошибка при определении местоположения пользователя', error);
      });
    }
  }, []);

  const handleMapClick = (latlng) => {
    console.log('Map clicked:', latlng);
  };

  const handleMapMove = () => {
    const map = L.map('map').getCenter();
    const zoom = L.map('map').getZoom();
    setMapCenter(map);
    setMapZoom(zoom);
    console.log('Map center:', map);
    console.log('Map zoom:', zoom);
  };

  useEffect(() => {
    if (userLocation) {
      setMapCenter(userLocation);
      setMapZoom(12);
    }
  }, [userLocation]);

  if(isLoading){
    return (
      <SplashScreen />
    )
  }

  return (
    <div className="flex flex-col h-screen">
      <div className="w-full">
        <MapView
          style={{ height: '40vh', width: '100%' }}
          markers={markers}
          onMapClick={handleMapClick}
          onMapMove={handleMapMove}
          isEditable={true}
          center={mapCenter}
          zoom={mapZoom}
          setOrgId={setOrgId}
          setUserOrgLocation={setUserOrgLocation}
        />
      </div>
      <div className="fixed bottom-0 left-0 w-full bg-white dark:bg-black rounded-t-lg shadow-md backdrop-blur-sm">
        <OrganizationInfo orgId={orgId} anotherOrg={anotherOrg} userOrgLocation={userOrgLocation}  />
      </div>
    </div>
  );
};

export default HomePage;