import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import axios from 'axios';
import Cookies from 'js-cookie';
import 'leaflet/dist/leaflet.css';

const MapView = ({ onMapClick, isEditable, setOrgId, setUserOrgLocation, style }) => {
  const [markers, setMarkers] = useState([]);
  const [position, setPosition] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Запрос местоположения пользователя
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords; 
          setUserLocation([latitude, longitude]);
          setUserOrgLocation([latitude, longitude]);
        },
        (err) => {
          console.error("Ошибка при получении местоположения пользователя", err);
          setUserOrgLocation([]);
        }
      );
    }

    const fetchOrganizations = async () => {
      try {
        const response = await axios.get('/backend/api/getOrganizations.php');
        const organizations = response.data;

        // Фильтруем организации с ненулевыми координатами
        const validMarkers = organizations
          .filter(org => org.latitude !== null && org.longitude !== null)
          .map(org => ({
            id: org.id,
            lat: parseFloat(org.latitude),
            lng: parseFloat(org.longitude),
            name: org.name
          }));

        setMarkers(validMarkers);
      } catch (error) {
        console.error('Ошибка при загрузке организаций', error);
      }
    };

    fetchOrganizations();
  }, []);

  // const MapClickHandler = () => {
  //   useMapEvents({
  //     click(e) {
  //       if (isEditable) {
  //         setPosition(e.latlng);
  //         onMapClick(e.latlng);
  //       }
  //     },
  //   });
  //   return position ? <Marker position={position}><Popup>Ваша организация</Popup></Marker> : null;
  // };

  const ChangeView = ({ center }) => {
    const map = useMap();
    map.setView(center);
    return null;
  };

  const handleMarkerClick = (orgId) => {
    setOrgId(orgId)
    console.log("Выбранная организация ID:", orgId); 
  };

  return (
    <MapContainer center={userLocation || [55.739, 52.408]} zoom={13} style={style}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="#">mastermap</a>'
      />
      {userLocation && <ChangeView center={userLocation} />}
      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={[marker.lat, marker.lng]}
          eventHandlers={{
            click: () => {
              handleMarkerClick(marker.id); // Вызов функции при клике на маркер
            },
          }}
        >
          <Popup>{marker.name}</Popup>
        </Marker>
      ))}
      {/* <MapClickHandler /> */}
    </MapContainer>
  );
};

export default MapView;
