// import React, { createContext, useContext, useState } from 'react';
// import Cookies from 'js-cookie'; // Подключаем библиотеку для работы с куки

// // Создание контекста
// const AuthContext = createContext();

// export const useAuth = () => useContext(AuthContext);

// export const AuthProvider = ({ children }) => {
//     // Используем куки вместо localStorage
//     const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('authToken'));

//     const login = (token) => {
//         // Сохраняем токен в куки
//         Cookies.set('authToken', token, { expires: 7 }); // 7 дней хранения
//         setIsAuthenticated(true);
//     };

//     const logout = () => {
//         // Удаляем токен из куки
//         Cookies.remove('authToken');
//         setIsAuthenticated(false);
//     };

//     return (
//         <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
//             {children}
//         </AuthContext.Provider>
//     );
// };

import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('authToken'));

    useEffect(() => {
        const token = Cookies.get('authToken');
        if (token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    const login = (token) => {
        Cookies.set('authToken', token, { expires: 7 });
        setIsAuthenticated(true);
    };

    const logout = () => {
        Cookies.remove('authToken');
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
