import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import {
    Box, Button, FormControl, FormLabel, Input, Text, VStack,
    Alert, AlertIcon, Spinner, Textarea
} from '@chakra-ui/react';

const RegisterPage = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [step, setStep] = useState(1); // Текущий шаг
    const [orgId, setOrgId] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        verificationCode: '',
        name: '',
        city: '',
        address: '',
        latitude: 0,
        longitude: 0
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Для определения центра карты пользователя (если включил местоположение)
    const [userLocation, setUserLocation] = useState(null);
    const [organization, setOrganization] = useState({latitude: 0, longitude: 0});

    useEffect(() => {
        // Запрос местоположения пользователя
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                  const { latitude, longitude } = pos.coords; 
                  setUserLocation([latitude, longitude]);
                },
                (err) => {
                  console.error("Ошибка при получении местоположения пользователя", err);
                }
          );
        }
    }, []);


    const handleMapClick = (e) => {
        const { lat, lng } = e.latlng;
        setFormData({ ...formData, latitude: lat, longitude: lng });
        setOrganization({latitude: lat, longitude: lng});
    };

    const MapEvents = () => {
        useMapEvents({
            click: handleMapClick,
        });
        return null;
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    // Обработка изменения полей формы
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    // Валидация email
    const validateEmail = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            setError('Некорректный email');
            return false;
        }
        setError('');
        return true;
    };

    // Валидация пароля
    const validatePasswords = () => {
        if (formData.password.length < 6) {
            setError('Пароль должен содержать не менее 6 символов');
            return false;
        }
        if (formData.password !== formData.confirmPassword) {
            setError('Пароли не совпадают');
            return false;
        }
        setError('');
        return true;
    };

    // Обработка первого шага
    const handleFirstStepSubmit = async (e) => {
        e.preventDefault();

        if (validateEmail() && validatePasswords()) {
            setIsLoading(true);
            try {
                // Отправка данных на сервер для генерации кода подтверждения
                const response = await axios.post('/backend/api/register.php', {
                    email: formData.email,
                    password: formData.password
                });

                if (response.data.success) {
                    setStep(2); // Переход ко второму шагу
                    setSuccessMessage("Код подтверждения отправлен на ваш Email");
                } else {
                    setError(response.data.message);
                }
            } catch (error) {
                setError("Ошибка отправки данных");
            } finally {
                setIsLoading(false);
            }
        }
    };

    // Обработка второго шага (подтверждение кода)
    const handleSecondStepSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            // Отправляем код подтверждения на сервер
            const response = await axios.post('/backend/api/verify.php', {
                email: formData.email,
                verificationCode: formData.verificationCode
            });

            const orgRes = await axios.post('/backend/api/getOrganizationId.php', {
                email: formData.email
            })

            if(orgRes.data.success){
                setOrgId(orgRes.data.org_id)
            }

            if (response.data.success) {
                setStep(3); // Переход к третьему шагу
                setSuccessMessage("Email подтвержден. Заполните данные о салоне.");
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError("Ошибка подтверждения кода");
        } finally {
            setIsLoading(false);
        }
    };

    // Обработка третьего шага
    const handleThirdStepSubmit = async (e) => {
        e.preventDefault();
        // Проверка обязательных полей
        if (!formData.salonName || !formData.city || !formData.salonAddress) {
            setError("Пожалуйста, заполните все поля");
            return;
        }
        if(!formData.latitude || !formData.longitude){
            setError("Пожалуйста, поставьте точку местонахождения вашего салона на карте");
            return;
        }
        setIsLoading(true);
        try {
            // Отправка дополнительных данных на сервер
            const response = await axios.post('/backend/api/updateOrganization.php', {
                name: formData.salonName,
                address: formData.salonAddress,
                city: formData.city,
                latitude: formData.latitude,
                longitude: formData.longitude,
                orgId: orgId
            });

            if (response.data.success) {
                navigate('/login'); // Успешная регистрация, перенаправление на логин
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError("Ошибка сохранения данных");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" bg="gray.100">
            <Box bg="white" p={8} rounded="md" shadow="lg" maxWidth="md" width="full">
                {error && (
                    <Alert status="error" mb={4}>
                        <AlertIcon />
                        {error}
                    </Alert>
                )}

                {successMessage && (
                    <Alert status="success" mb={4}>
                        <AlertIcon />
                        {successMessage}
                    </Alert>
                )}

                {isLoading && (
                    <Spinner size="xl" mb={4} />
                )}

                {step === 1 && (
                    <form onSubmit={handleFirstStepSubmit}>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    type="email"
                                    name="email"
                                    placeholder="Введите email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    isRequired
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Пароль</FormLabel>
                                <Input
                                    type="password"
                                    name="password"
                                    placeholder="Введите пароль"
                                    value={formData.password}
                                    onChange={handleChange}
                                    isRequired
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Подтверждение пароля</FormLabel>
                                <Input
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Подтвердите пароль"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    isRequired
                                />
                            </FormControl>
                            <Button type="submit" colorScheme="blue" width="full">Зарегистрироваться</Button>
                        </VStack>
                    </form>
                )}

                {step === 2 && (
                    <form onSubmit={handleSecondStepSubmit}>
                        <VStack spacing={4}>
                            <Text>На ваш Email отправлен код подтверждения. Пожалуйста, введите его ниже.</Text>
                            <FormControl>
                                <FormLabel>Код подтверждения</FormLabel>
                                <Input
                                    type="text"
                                    name="verificationCode"
                                    placeholder="Введите код"
                                    value={formData.verificationCode}
                                    onChange={handleChange}
                                    isRequired
                                />
                            </FormControl>
                            <Button type="submit" colorScheme="blue" width="full">Подтвердить</Button>
                        </VStack>
                    </form>
                )}

                {step === 3 && (
                    <form onSubmit={handleThirdStepSubmit}>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Название салона</FormLabel>
                                <Input
                                    type="text"
                                    name="salonName"
                                    placeholder="Введите название салона"
                                    value={formData.salonName}
                                    onChange={handleChange}
                                    isRequired
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Город</FormLabel>
                                <Input
                                    type="text"
                                    name="city"
                                    placeholder="Введите город"
                                    value={formData.city}
                                    onChange={handleChange}
                                    isRequired
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Адрес салона</FormLabel>
                                <Input
                                    type="text"
                                    name="salonAddress"
                                    placeholder="Введите адрес салона"
                                    value={formData.salonAddress}
                                    onChange={handleChange}
                                    isRequired
                                />
                            </FormControl>
                            <FormControl>
                                <div className="mt-6">
                                    <MapContainer center={userLocation || [55.739, 52.408]} zoom={13} style={{ height: "50vh", width: "100%" }}>
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                        {organization.latitude && organization.longitude && (
                                            <Marker position={[organization.latitude, organization.longitude]}>
                                                <Popup>
                                                    {formData.name}<br/>{formData.address}
                                                </Popup>
                                            </Marker>
                                        )}
                                        <MapEvents />
                                    </MapContainer>
                                </div>
                            </FormControl>
                            <Button type="submit" colorScheme="blue" width="full">Завершить регистрацию</Button>
                        </VStack>
                    </form>
                )}
            </Box>
        </Box>
    );
};

export default RegisterPage;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link, useNavigate } from 'react-router-dom';
// import { useAuth } from '../context/auth';

// const RegisterPage = () => {
//     const navigate = useNavigate();
//     const { login, isAuthenticated } = useAuth();
//     const [organization, setOrganization] = useState({
//         name: '',
//         city: '',
//         address: '',
//         email: '',
//         password: ''
//     });
//     const [error, setError] = useState('');

//     useEffect(() => {
//         if (isAuthenticated) {
//             navigate('/');
//         }
//     }, [isAuthenticated, navigate]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setOrganization(prevState => ({ ...prevState, [name]: value }));
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         axios.post('/backend/api/register.php', organization)
//             .then(response => {
//                 if (response.data.success) {
//                     navigate('/login');
//                 } else {
//                     setError(response.data.message);
//                 }
//             })
//             .catch(error => {
//                 setError("Ошибка регистрации!");
//                 console.error("Ошибка регистрации!", error);
//             });
//     };

//     return (
//         <div className="flex items-center justify-center h-screen bg-gray-100">
//             <div className="bg-white dark:bg-black rounded-lg shadow-lg p-6 w-full max-w-md">
//                 <Link to={"/"} className="bg-gray-300 text-gray-800 dark:bg-bgDark dark:text-white font-bold py-2 px-4 me-4 rounded focus:outline-none focus:shadow-outline mb-4">Назад</Link>
//                 <Link to={"/login"} className="bg-gray-300 text-gray-800 dark:bg-bgDark dark:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4">Уже есть аккаунт?</Link>
//                 {error && <p>{error}</p>}
//                 <h2 className="text-2xl font-semibold text-center dark:text-white mb-6 mt-6">
//                     Регистрация
//                 </h2>
//                 <form onSubmit={handleSubmit} className="space-y-4">
//                     <div>
//                         <label htmlFor="name" className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
//                             Наименование организации
//                         </label>
//                         <input
//                             type="text"
//                             id="name"
//                             name="name"
//                             placeholder="Введите наименование организации"
//                             value={organization.name}
//                             onChange={handleChange}
//                             className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 dark:bg-bgDark dark:text-white focus:outline-none focus:shadow-outline"
//                         />
//                     </div>
//                     <div>
//                         <label htmlFor="city" className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
//                             Город
//                         </label>
//                         <input
//                             type="text"
//                             id="city"
//                             name="city"
//                             placeholder="Введите город"
//                             value={organization.city}
//                             onChange={handleChange}
//                             className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 dark:bg-bgDark dark:text-white focus:outline-none focus:shadow-outline"
//                         />
//                     </div>
//                     <div>
//                         <label htmlFor="address" className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
//                             Адрес
//                         </label>
//                         <input
//                             type="text"
//                             id="address"
//                             name="address"
//                             placeholder="Введите адрес"
//                             value={organization.address}
//                             onChange={handleChange}
//                             className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 dark:bg-bgDark dark:text-white focus:outline-none focus:shadow-outline"
//                         />
//                     </div>
//                     <div>
//                         <label htmlFor="email" className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
//                             Email
//                         </label>
//                         <input
//                             type="email"
//                             id="email"
//                             name="email"
//                             placeholder="Введите email"
//                             value={organization.email}
//                             onChange={handleChange}
//                             className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 dark:bg-bgDark dark:text-white focus:outline-none focus:shadow-outline"
//                         />
//                     </div>
//                     <div>
//                         <label htmlFor="password" className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
//                             Пароль
//                         </label>
//                         <input
//                             type="password"
//                             id="password"
//                             name="password"
//                             placeholder="Введите пароль"
//                             value={organization.password}
//                             onChange={handleChange}
//                             className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 dark:bg-bgDark dark:text-white focus:outline-none focus:shadow-outline"
//                         />
//                     </div>
//                     <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-6">
//                         Регистрация
//                     </button>
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default RegisterPage;
