import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    TabPanel, TabPanels, TabList, Tabs, Tab,
    Divider,
    Text
} from "@chakra-ui/react";
import MaskedInput from 'react-text-mask';
import axios from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import { useAuth } from '../context/auth';
import Loader from '../components/Loader';
import 'leaflet/dist/leaflet.css';

const OrganizationDashboard = () => {
    const navigate = useNavigate();
    const [organization, setOrganization] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { isAuthenticated, logout } = useAuth();


    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get('/validateToken.php');
                if (!response.data.success) {
                    logout();
                    navigate('/login');
                    // console.log(response.data)
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error('Ошибка проверки токена', error);
                logout();
                navigate('/login');
            }
        };
    
        if (!isAuthenticated) {
            navigate('/login');
        } else {
            checkAuth();
        }
    }, [isAuthenticated, logout, navigate]);
    

    useEffect(() => {
        axios.get('/getCurrentOrganization.php')
            .then(response => {
                if (response.data.success) {
                    setOrganization(response.data.data);
                    // console.log(response.data.data)
                } else {
                    navigate('/login');
                }
                setLoading(false);
            })
            .catch(error => {
                setError("Ошибка при получении данных организации!");
                setLoading(false);
            });
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrganization({ ...organization, [name]: value });
    };

    const handleSave = (e) => {
        e.preventDefault();
        axios.post('/updateOrganization.php', organization)
            .then(response => {
                if (!response.data.success) {
                    setError(response.data.message);
                } else {
                    navigate('/');
                }
            })
            .catch(error => {
                setError("Ошибка сохранения данных!");
            });
    };

    const handleMapClick = (e) => {
        const { lat, lng } = e.latlng;
        setOrganization({ ...organization, latitude: lat, longitude: lng });
    };

    const handleLogout = () => { logout(); };

    const MapEvents = () => {
        useMapEvents({
            click: handleMapClick,
        });
        return null;
    };

    if (loading) {
        return  (
            <Loader height="100vh" />
        ); 
    }

    if (!organization) {
        return  (
            <Loader height="100vh" />
        );
    }

    return (
        <div className="flex justify-center bg-gray-100 mt-5">
            <div className="bg-white dark:bg-black rounded-lg shadow-lg p-6 w-full max-w-md">
                <button
                    className="bg-gray-300 dark:bg-bgDark text-gray-800 dark:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4"
                    onClick={handleLogout}
                >
                    Выйти
                </button>
                {error && <p>{error}</p>}
                <h2 className="text-2xl font-semibold text-center dark:text-white mb-6">
                    Панель организации
                </h2>

                <Tabs variant='unstyled'>
                    <TabList flexDirection={"column"}>
                      <Tab className='items-center text-gray-800 bg-bgLight dark:bg-bgDark dark:text-white m-2 rounded-md px-4 py-2 w-full sm:w-auto' _selected={{ bg: 'bgSelectedComponent', color: "white" }}>Редактирование организации</Tab>
                      <Tab className='items-center text-gray-800 bg-bgLight dark:bg-bgDark dark:text-white m-2 rounded-md px-4 py-2 w-full sm:w-auto' _selected={{ bg: 'bgSelectedComponent', color: "white" }}>Добавление мастера</Tab>
                      <Tab className='items-center text-gray-800 bg-bgLight dark:bg-bgDark dark:text-white m-2 rounded-md px-4 py-2 w-full sm:w-auto' _selected={{ bg: 'bgSelectedComponent', color: "white" }}>Список мастеров</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <form onSubmit={handleSave} className="space-y-4">
                                <div>
                                    <label htmlFor="name" className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
                                        Наименование организации
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Введите наименование организации"
                                        value={organization.name}
                                        onChange={handleChange}
                                        className="shadow appearance-none dark:bg-bgDark dark:text-white rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="city" className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
                                        Город
                                    </label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        placeholder="Введите город"
                                        value={organization.city}
                                        onChange={handleChange}
                                        className="shadow appearance-none dark:bg-bgDark dark:text-white rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="address" className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
                                        Адрес
                                    </label>
                                    <input
                                        type="text"
                                        id="address"
                                        name="address"
                                        placeholder="Введите адрес"
                                        value={organization.address}
                                        onChange={handleChange}
                                        className="shadow appearance-none dark:bg-bgDark dark:text-white rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                                    />
                                </div>
                                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-6">
                                    Сохранить
                                </button>
                            </form>
                            <div className="mt-6">
                                <MapContainer center={[organization.latitude || 37.7749, organization.longitude || -122.4194]} zoom={13} style={{ height: "50vh", width: "100%" }}>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                    {organization.latitude && organization.longitude && (
                                        <Marker position={[organization.latitude, organization.longitude]}>
                                            <Popup>
                                                {organization.name}<br/>{organization.address}
                                            </Popup>
                                        </Marker>
                                    )}
                                    <MapEvents />
                                </MapContainer>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <AddMasterForm organization_id={organization.id} />
                        </TabPanel>
                        <TabPanel>
                            <MastersList organization_id={organization.id}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        </div>
    );
};

export default OrganizationDashboard;


const AddMasterForm = ({ organization_id }) => {
    const [master, setMaster] = useState({
        name: '',
        phone: '',
        org_id: organization_id
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;

        setMaster({
            ...master,
            [name]: value,
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        console.log(master);
        axios.post('/createNewMaster.php', master)
            .then(response => {
                if (!response.data.success) {
                    setError(response.data.message);
                } else {
                    navigate('/');
                }
            })
            .catch(error => {
                setError("Ошибка сохранения данных!");
            });
        // здесь можно добавить логику сохранения
    };

    return (
        <Box maxW="md" mx="auto" bg="black" borderRadius="lg">
            <form onSubmit={handleSave}>
                <FormControl id="name" mb="4">
                    <FormLabel color="white">Имя</FormLabel>
                    <Input
                        type="text"
                        name="name"
                        _focus={{ outline: false }}
                        border={"none"}
                        placeholder="Введите имя мастера"
                        value={master.name}
                        onChange={handleChange}
                        bg="bgDark"
                        color="white"
                    />
                </FormControl>

                <FormControl id="phone" mb="4">
                    <FormLabel color="white">Номер телефона</FormLabel>
                    <MaskedInput
                        mask={['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                        placeholder="Введите номер телефона"
                        _focus={{ outline: false }}
                        border={"none"}
                        value={master.phone}
                        onChange={(e) => handleChange(e)}
                        render={(ref, props) => (
                            <Input
                                ref={ref}
                                {...props}
                                name="phone"
                                bg="bgDark"
                                color="white"
                            />
                        )}
                    />
                </FormControl>

                <Button type="submit" colorScheme="blue" width="full" mt="6">
                    Сохранить
                </Button>
            </form>
        </Box>
    );
};

const MastersList = ({organization_id}) => {
    const [masters, setMasters] = useState([])

    useEffect(() => {
        axios.post('/getMastersInfoForOrganizaiton.php', {
            org_id: organization_id
        })
        .then(response => {
            if(response.data.success){
                setMasters(response.data.data);
            } else {
                console.log("Не удалось вывести мастеров");
            }
        })
        .catch(error => {
            console.error("Произошла ошибка при запросе данных:", error);
        });
    }, [organization_id]);

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Text className='dark:text-white' fontSize={"2xl"} pb={3}>Список мастеров</Text>
            {masters ? 
            masters.map((master, index) => (
                <div key={index}>
                    <Text className='dark:text-white'>Имя: {master.name}</Text>
                    <Text className='dark:text-white'>Телефон: {master.phone}</Text>
                    <Text className='dark:text-white'>{master.is_available === 1 ? "Свободен" : "Занят"}</Text>
                    <Divider mt={3} mb={3} />
                </div>
            ))
            :
            <Text className='dark:text-white'>Пока что у вас нет мастеров</Text>
            }
        </Box>
    )
}

