import React from 'react'
import { Button, Input, FormControl, FormLabel, Box, Heading } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
const FirstForm = () => {
    const navigate = useNavigate();
    return (
        <div className='flex items-center justify-center h-screen bg-gray-100'>
            <Box as="form" className="w-full max-w-md mx-auto mt-10 p-4 border rounded-md dark:bg-black ">
                <Heading as="h1" size="lg" mb={6} className='dark:text-white'>
                  Зарегистрировать свою услугу/салон
                </Heading>
                <FormControl mb={4}>
                  <FormLabel className='dark:text-white'>E-mail</FormLabel>
                  <Input type="email" placeholder="Введите адрес почты" className='dark:text-white focus:outline-none focus:shadow-outline' />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel className='dark:text-white'>Создайте пароль</FormLabel>
                  <Input type="password" placeholder="Введите пароль" className='dark:text-white focus:outline-none focus:shadow-outline' />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel className='dark:text-white'>Подтвердите пароль</FormLabel>
                  <Input type="password" placeholder="Подтвердите пароль" className='dark:text-white focus:outline-none focus:shadow-outline' />
                </FormControl>
                <Button colorScheme="green" className="w-full" onClick={() => navigate('/form2')} >Создать</Button>
            </Box>
        </div>
      );
}

export default FirstForm