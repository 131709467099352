import React from 'react';
import { Box, Text, Spinner } from '@chakra-ui/react';

const SplashScreen = () => {
  return (
    <Box 
      position="fixed"
      top="0"
      left="0"
      height="100vh" 
      width="100vw" 
      display="flex" 
      flexDirection="column" 
      justifyContent="center" 
      alignItems="center" 
      bgColor="black"
      gap="30px"
    >
      <Text fontSize="100px" fontWeight="700" color="SplashScreenColor">M</Text>
      <Spinner size='xl' color='SplashScreenColor' />
    </Box>
  );
}

export default SplashScreen;