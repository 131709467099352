import React, { useState } from 'react'
import { Button, Box, FormLabel, Input, Heading } from "@chakra-ui/react";

const ThirdForm = () => {
    const [salonImage, setSalonImage] = useState(null);
    const [masterImage, setMasterImage] = useState(null);

    const handleSalonImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSalonImage(URL.createObjectURL(file));
      }
    };

    const handleMasterImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setMasterImage(URL.createObjectURL(file));
      }
    };

    return (
        <div className='flex items-center justify-center h-screen bg-gray-100'>
            <Box className="w-full max-w-md mx-auto mt-10 p-4 border rounded-md dark:bg-black">
                <Heading as="h1" size="lg" mb={6} className='dark:text-white'>
                    Личный кабинет
                </Heading>
                <Box mb={4}>
                    <FormLabel className='dark:text-white'>Название</FormLabel>
                    <Input type="email" placeholder="Название вашего салона" className='dark:text-white focus:outline-none focus:shadow-outline' />
                </Box>
                <Box mb={4}>
                    <FormLabel className='dark:text-white'>Адрес</FormLabel>
                    <Input type="email" placeholder="Адрес вашего салона" className='dark:text-white focus:outline-none focus:shadow-outline' />
                </Box>

                {/* Инпут для загрузки фото салона */}
                <Box mb={4} textAlign="center" position="relative">
                    <FormLabel className='dark:text-white text-center w-auto'>Добавить фото салона</FormLabel>
                    <Box className="relative w-48 h-48 mx-auto mb-2">
                        {salonImage ? (
                        <img
                            src={salonImage}
                            alt="Фото салона"
                            className="w-full h-full object-cover rounded"
                        />
                        ) : (
                        <Box className="w-full h-full bg-gray-200 dark:bg-black dark:text-white dark:border-white dark:border-2 border-solid rounded-md flex items-center justify-center">
                            <span className="text-3xl font-bold">+</span>
                        </Box>
                        )}
                        <Input
                        type="file"
                        accept="image/*"
                        onChange={handleSalonImageChange}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                            zIndex: 1,
                        }}
                        />
                    </Box>
                </Box>

                {/* Инпут для загрузки фото мастера */}
                <Box mb={4} textAlign="center" position="relative">
                    <FormLabel className='dark:text-white text-center'>Добавить мастера</FormLabel>
                    <Box className="relative w-48 h-48 mx-auto mb-2">
                        {masterImage ? (
                        <img
                            src={masterImage}
                            alt="Фото мастера"
                            className="w-full h-full object-cover rounded"
                        />
                        ) : (
                        <Box className="w-full h-full bg-gray-200 dark:bg-black dark:text-white dark:border-white dark:border-2 border-solid rounded-md flex items-center justify-center">
                            <span className="text-3xl font-bold">+</span>
                        </Box>
                        )}
                        <Input
                        type="file"
                        accept="image/*"
                        onChange={handleMasterImageChange}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                            zIndex: 1,
                        }}
                        />
                    </Box>
                </Box>

                <Button colorScheme="green" className="w-full">Сохранить</Button>
            </Box>
        </div>
    );
}

export default ThirdForm