import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import OrganizationDashboard from './pages/OrganizationDashboard';
import AdminDashboard from './pages/AdminDashboard';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import './App.css'; 
import { AuthProvider } from './context/auth';
import TooglerTheme from './components/TooglerTheme';
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme';
import FirstForm from './components/FirstForm';
import SecondForm from './components/SecondForm';
import ThirdForm from './components/ThirdForm';
// import Notify from './components/Notify';
function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Router>
          <TooglerTheme />
          {/* <Notify /> */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/organization" element={<OrganizationDashboard />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            {/* что за формы? надо переименовать */}
            <Route path="/form1" element={<FirstForm />}/>
            <Route path="/form2" element={<SecondForm />}/>
            <Route path="/form3" element={<ThirdForm />}/>
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
