import React, { useState } from 'react'
import { Button, Input, FormControl, FormLabel, Box, Heading } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const markers = [
    { lat: 37.7749, lng: -122.4194, name: 'Marker 1' },
    { lat: 37.7797, lng: -122.4139, name: 'Marker 2' },
    // Добавьте нужные маркеры с координатами и названиями
];

const SecondForm = () => {
    const navigate = useNavigate();

    const handleMapClick = (latlng) => {
        // Логика обработки клика по карте (если необходимо)
        console.log('Map clicked:', latlng);
    };

    return (
        <div className='flex items-center justify-center h-screen bg-gray-100'>
            <Box as="form" className="w-full max-w-md mx-auto mt-10 p-4 border rounded-md dark:bg-black">
                <Heading as="h1" size="lg" mb={6} className='dark:text-white'>
                  Заполните поля
                </Heading>
                <FormControl mb={4}>
                  <FormLabel className='dark:text-white'>Название</FormLabel>
                  <Input className='dark:text-white focus:outline-none focus:shadow-outline' placeholder="Введите название" />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel className='dark:text-white'>Укажите на карте местоположение</FormLabel>
                  <Box as="div" className="h-64 bg-gray-200">
                    <MapView markers={markers} onMapClick={handleMapClick} isEditable={true} />
                    {/* Здесь будет карта */}
                  </Box>
                </FormControl>
                <Button colorScheme="green" className="w-full" onClick={() => navigate('/form3')} >Сохранить</Button>
            </Box>
        </div>
    );
}


const MapView = ({ markers, onMapClick, isEditable }) => {
    const [position, setPosition] = useState(null);
  
    const MapClickHandler = () => {
      useMapEvents({
        click(e) {
          if (isEditable) {
            setPosition(e.latlng);
            onMapClick(e.latlng);
          }
        },
      });
      return position ? <Marker position={position}><Popup>Ваша организация</Popup></Marker> : null;
    };
  
    return (
      <MapContainer center={[55.739, 52.408]} zoom={13} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="#">mastermap</a>'
        />
        {markers.map((marker, index) => (
          <Marker key={index} position={[marker.lat, marker.lng]}>
            <Popup>{marker.name}</Popup>
          </Marker>
        ))}
        <MapClickHandler />
      </MapContainer>
    );
};

export default SecondForm